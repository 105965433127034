import React, { Fragment } from 'react'
import Footer from '../components/FooterSite'

import {
  inspired,
  container,
  columns, 
} from './styles/FacaParte.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={columns}>
          <Footer />
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
