import React, { Component } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import * as Scroll from 'react-scroll'
import NavBar from '../components/NavBar'
import Banner from '../components/HeroBannerSite'
import SobreAOlimpiada from '../components/SobreAOlimpiada'
import Apps from '../components/Apps'
import NaMidia from '../components/NaMidia'
import FacaParte from '../components/FacaParte'

import { globalStyles } from '../components/layout.styles'

export const MenuContext = React.createContext({
  visible: false,
  setVisible: () => {}
})

class SitePage extends Component {
  constructor (props) {
    super(props)
    this.setVisible = (value) => {
      this.setState(({ visible: value }))
    }

    this.state = {
      visible: false,
      setVisible: this.setVisible
    }
  }

  render () {
    
    return (
      <div>
        <Global styles={globalStyles} />
        <SEO title="Mês Nacional da Ciência, Tecnologia e Inovações" />
        <MenuContext.Provider value={this.state}>
          <Scroll.Element
            id='header'
          >
            <NavBar location={this.props.location} /> 
          </Scroll.Element>
          <Banner />
          <Scroll.Element>
            <div id='sobre-a-olimpiada' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <SobreAOlimpiada />
          </Scroll.Element>
          <Scroll.Element>
            <div id='apps' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <Apps />
          </Scroll.Element>
          <Scroll.Element>
            <div style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <NaMidia />
          </Scroll.Element>
          <Scroll.Element>
            <div style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <FacaParte />
          </Scroll.Element>
        </MenuContext.Provider>
      </div>
    )
  }
}

export default SitePage
