import React from 'react'
import GPlay from '../images/android.svg'
import AppStore from '../images/ios.svg'
import App from '../images/app.png'

import {
  StyleJornadaSapientia,
  title2,
  subTitle,
  container,
  aboutContent,
  columns,
  column,
  apps,
  AppContent
} from './styles/ComoEstudar.styles'
import { desktop, mobile } from './styles/WebSerieSapientia.styles'

const AOlimpiada = props => (
  <div css={StyleJornadaSapientia}>
    <div css={container}>
      <div css={columns}>
        <div css={[column, desktop]}>
          <img src={App} alt='Como Estudar' />
        </div>
        <div css={[column, AppContent]}>
          <div css={aboutContent}>
            <div css={subTitle}>BAIXE O APP</div>
            <div css={title2}>ESTUDANDO E APRENDENDO SOBRE TUDO O QUE A TECNOLOGIA E A CIÊNCIA SÃO CAPAZES</div>
            <div css={mobile}>
              <img src={App} alt='Como Estudar' />
            </div>
            <div css={apps}>
              <a href='https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.mncti' title='Disponível no Google Play' target='_blank' rel='noopener noreferrer'>
                <img src={GPlay} alt='/' style={{ marginRight: 24 }} />
              </a>
              <a href='/' title='Disponível na App Store' target='_blank' rel='noopener noreferrer'>
                <img src={AppStore} alt='/' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AOlimpiada
