import React, { Fragment, useState } from 'react'
import Logo from '../images/selo.svg'
import { Link } from 'gatsby'
import * as Scroll from 'react-scroll'
import ResponsiveMenu from './navbar/ResponsiveNavbar'
import OpenIcon from '../images/open_menu.svg'
import CloseIcon from '../images/close_menu.svg'
import Youtube from '../images/youtube.svg'
import Facebook from '../images/facebook.svg'
import Instagram from '../images/instagram.svg'
import Twitter from '../images/twitter.svg'
import {
  navBar,
  container,
  logo,
  navBarMenu,
  SingUpBtn,
  LoginBtn,
  GoToDashboard,
  navMenu,
  mobileMenu,
  togle,
  linkTo,
  socialIcons
} from './styles/Navbar.styles'

const NavBar = props => {
  const [visible, setVisible] = useState(false)
  return (
    <div css={navBar}>
      <div css={container}>
        <div css={logo} title='Mês Nacional da Ciência, Tecnologia e Inovações'>
          <Link
            to='header'
            offsetTop={100}
            onFocus={() => setVisible(false)}
          >
            <Link to={props.isLogged ? '/home' : '/'}><img src={Logo} alt='Logo' /></Link>
          </Link>
        </div>
        <div css={navMenu}
          style={{
            display: props.isMenuDisabled ? 'none' : 'flex'
          }}>
          <ResponsiveMenu
            menuOpenButton={<img src={OpenIcon} alt='/' />}
            menuCloseButton={<img src={CloseIcon} alt='/' />}
            changeMenuOn='960px'
            largeMenuClassName={navBarMenu}
            smallMenuClassName={mobileMenu}
            menuVisible={visible}
            setMenu={setVisible}
            menu={
              <div css={togle}>
                {props.location && !props.location.pathname.match(/^\/(#.*|\?.*)*$/)?
                  (
                    <Fragment>
                      <Link
                        to='/#sobre-a-olimpiada'
                        css={linkTo}
                      >
                        CONHEÇA A OLIMPÍADA
                      </Link>
                      <Link
                        to='/#apps'
                        css={linkTo}
                      >
                        BAIXE O APP
                      </Link>
                    </Fragment>
                  ):
                  (
                    <Fragment>
                      <Scroll.Link
                        to='sobre-a-olimpiada'
                        smooth={true} 
                        duration={500}
                      >
                        CONHEÇA A OLIMPÍADA
                      </Scroll.Link>
                      <Scroll.Link
                        to='apps'
                        smooth={true} 
                        duration={500}
                      >
                        BAIXE O APP
                      </Scroll.Link>
                    </Fragment>
                  )
                }
                {false && <Fragment>
                  {
                    !props.isLogged
                      ? (
                        <Fragment>
                          <a
                            href='http://bit.ly/sapientia20'
                            css={SingUpBtn}
                          >
                            Inscreva-se
                          </a>
                          <a
                            css={LoginBtn}
                            href='https://app.olimpiadadofuturo.com.br/'
                          >
                            Login
                          </a>
                        </Fragment>
                      )
                      : <Link
                        to='/home'
                        css={GoToDashboard}>
                          Login
                      </Link>
                  }
                </Fragment>}
                <div css={socialIcons}>
                  <a href='https://www.youtube.com/mctic'><img src={Youtube} alt='Youtube' /></a>
                  <a href='https://www.facebook.com/mctic'><img src={Facebook} alt='Facebook' /></a>
                  <a href='https://www.instagram.com/mctic'><img src={Instagram} alt='Instagram' /></a>
                  <a href='https://www.twitter.com/mctic'><img src={Twitter} alt='Twitter' /></a>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default NavBar
