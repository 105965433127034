import React from 'react'
import { Link } from 'gatsby'
import SobreAOlimpiada from '../images/a-olimpiada.png'

import {
  StyleJornadaSapientia,
  title,
  subTitle,
  container,
  aboutContent,
  columns,
  column,
  btn
} from './styles/ComoEstudar.styles'

import { desktop, mobile } from './styles/WebSerieSapientia.styles'

const AOlimpiada = props => (
  <div css={StyleJornadaSapientia}>
    <div css={container}>
      <div css={columns}>
        <div css={column}>
          <div css={aboutContent}>
            <div css={subTitle}>CONHEÇA A OLIMPÍADA</div>
            <div css={title}>UMA OLIMPÍADA NACIONAL PARA OS CIENTISTAS DE AMANHÃ</div>
            <div css={mobile}>
              <img src={SobreAOlimpiada} alt='UMA OLIMPÍADA NACIONAL PARA OS CIENTISTAS DE AMANHÃ' />
            </div>
            <p>O Mês Nacional da Ciência, Tecnologia e Inovação, o <b>MCTI</b>, em conjunto com suas vinculadas, promove uma série de atividades para divulgar a ciência e seus avanços. Especialmente para inspirar os estudantes do país, ocorrerá uma olimpíada escolar nacional sobre ciência e tecnologia, elaborada conjuntamente a dezenas de instituições de pesquisa e atividade científica, com a colaboração das principais olimpíadas de conhecimento do Brasil. A ciência e a tecnologia vista sobre múltiplos olhares, em suas mais inovadoras descobertas e práticas, aproximando-se da realidade dos estudantes e inspirando nossos futuros cientistas</p>
            <Link
              to='projeto-pedagogico'
            >
              <div css={btn}>
                FAÇA SUA INSCRIÇÃO
              </div>
            </Link>
          </div>
        </div>
        <div css={[column, desktop]}>
          <img src={SobreAOlimpiada} alt='UMA OLIMPÍADA NACIONAL PARA OS CIENTISTAS DE AMANHÃ' />
        </div>
      </div>
    </div>
  </div>
)

export default AOlimpiada
