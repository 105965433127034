import React from 'react'
import Vertere from '../images/vertere.svg'
import MCTIC from '../images/logo.svg'
import GOV from '../images/gov.svg'
import CEFET from '../images/parterns/CEFET.png'
import OBA from '../images/parterns/OBA.png'
import OBAP from '../images/parterns/OBAP.png'
import OBF from '../images/parterns/OBF.png'
import OBI from '../images/parterns/OBI.png'
import OBMEP from '../images/parterns/OBMEP.png'
import OBR from '../images/parterns/OBR.png'
import OBRAC from '../images/parterns/OBC.png'
import OBG from '../images/parterns/OBG.png'
import OBB from '../images/parterns/OBB.png'
import PNOQ from '../images/parterns/PNOQ.png'
import ONC from '../images/parterns/ONC.png'
import ONDA from '../images/parterns/ONDA.png'
import OBS from '../images/parterns/OBDS.png'
import OBSMA from '../images/parterns/OBS.png'
import OBN from '../images/parterns/OBN.png'
import OBM from '../images/parterns/OBM.png'
import AEB from '../images/parterns/AEB.png'
import CBPF from '../images/parterns/CBPF.png'
import CEITEC from '../images/parterns/CEITEC.png'
import CEMADEN from '../images/parterns/CEMADEN.png'
import CETEM from '../images/parterns/CETEM.png'
import CETENE from '../images/parterns/CETENE.png'
import CGEE from '../images/parterns/CGEE.png'
import CNEN from '../images/parterns/CNEN.png'
import CNPEM from '../images/parterns/CNPEM.png'
import CNPQ from '../images/parterns/CNPQ.png'
import CTI from '../images/parterns/CTI.png'
import EMBRAPII from '../images/parterns/EMBRAPII.png'
import FINEP from '../images/parterns/FINEP.png'
import IBICT from '../images/parterns/IBICT.png'
import IMPA from '../images/parterns/IMPA.png'
import INMA from '../images/parterns/INMA.png'
import INPA from '../images/parterns/INPA.png'
import INPE from '../images/parterns/INPE.png'
import INSA from '../images/parterns/INSA.png'
import INT from '../images/parterns/INT.png'
import LNA from '../images/parterns/LNA.png'
import LNCC from '../images/parterns/LNCC.png'
import MAMIRAUA from '../images/parterns/MAMIRAUA.png'
import MAST from '../images/parterns/MAST.png'
import MUSEUGOELDI from '../images/parterns/MUSEUGOELDI.png'
import ON from '../images/parterns/ON.png'
import ONHB from '../images/parterns/ONHB.png'
import OBECON from '../images/parterns/OBECON.png'
import OBLING from '../images/parterns/OBLING.png'
import POCKET from '../images/parterns/POCKET.png'
import RPN from '../images/parterns/RPN.png'
import SAPIENTIA from '../images/parterns/SAPIENTIA.png'
import VITALIS from '../images/parterns/VITALIS.png'
import white from '../images/parterns/white.png'

import {
  footer,
  sectionHeading,
} from './styles/Footer.styles'

class FooterSite extends React.Component {
  state = {
    visible: false
  }

  render () {
    return (
      <div css={footer}>
        <div css={sectionHeading} style={{ justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex' }}>
            <a href='/' title='17ª Semana Nacional De Ciência e Tecnologia' target='_blank' rel='noopener noreferrer'>
              <img src={MCTIC} alt='17ª Semana Nacional De Ciência e Tecnologia' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='http://www.mctic.gov.br/portal' title='Ministério da Ciência, Tecnologia, Inovações e Comunicações' target='_blank' rel='noopener noreferrer'>
              <img src={GOV} alt='Ministério da Ciência, Tecnologia, Inovações e Comunicações' />
            </a>
          </div>
        </div>
        <div css={sectionHeading} style={{ marginTop: 60 }}>
          <div style={{ display: 'flex' }}>
            <a href='https://www.institutovertere.com.br/' title='Instituto Vertere' target='_blank' rel='noopener noreferrer'>
              <img src={Vertere} alt='Instituto Vertere' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={AEB} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CBPF} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://www.cefetmg.br/' title='CEFET-MG' target='_blank' rel='noopener noreferrer'>
              <img src={CEFET} alt='CEFET-MG' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CEITEC} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CEMADEN} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CETEM} alt='' />
            </a>
          </div>
        </div>
        <div css={sectionHeading} style={{ marginTop: 30 }}>
         <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CETENE} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CGEE} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CNEN} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CNPEM} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CNPQ} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={CTI} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={EMBRAPII} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={FINEP} alt='' />
            </a>
          </div>
        </div>
        <div css={sectionHeading} style={{ marginTop: 30 }}>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={IBICT} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={IMPA} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={INMA} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={INPA} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={INPE} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={INSA} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={INT} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={LNA} alt='' />
            </a>
          </div>
        </div>
        <div css={sectionHeading} style={{ marginTop: 30 }}>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={LNCC} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={MAMIRAUA} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={MAST} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={MUSEUGOELDI} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='http://www.oba.org.br/' title='Olimpíada Brasileira de Astronomia' target='_blank' rel='noopener noreferrer'>
              <img src={OBA} alt='Olimpíada Brasileira de Astronomia' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://obap.ifsuldeminas.edu.br/' title='Olimpíada Brasileira de Agropecuária' target='_blank' rel='noopener noreferrer'>
              <img src={OBAP} alt='Olimpíada Brasileira de Agropecuária' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='http://www.olimpiadasdebiologia.butantan.gov.br/' title='Olimpíada Brasileira de Biologia' target='_blank' rel='noopener noreferrer'>
              <img src={OBB} alt='Olimpíada Brasileira de Biologia' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='http://olimpiadadecartografia.uff.br/' title='Olimpíada Brasileira de Cartografia' target='_blank' rel='noopener noreferrer'>
              <img src={OBRAC} alt='Olimpíada Brasileira de Cartografia' />
            </a>
          </div>
        </div>
        <div css={sectionHeading} style={{ marginTop: 30 }}>
          <div style={{ display: 'flex' }}>
            <a href='https://obsat.org.br/' title='Olimpíada Brasileira de Satélites' target='_blank' rel='noopener noreferrer'>
              <img src={OBS} alt='Olimpíada Brasileira de Satélites' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={OBECON} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='http://www.sbfisica.org.br/v1/olimpiada/' title='Olimpiada Brasileira de Física' target='_blank' rel='noopener noreferrer'>
              <img src={OBF} alt='Olimpiada Brasileira de Física' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://obgeografia.org/' title='Olimpíada Brasileira de Geografia' target='_blank' rel='noopener noreferrer'>
              <img src={OBG} alt='Olimpíada Brasileira de Geografia' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://olimpiada.ic.unicamp.br/' title='Olimpíada Brasileira de Informática' target='_blank' rel='noopener noreferrer'>
              <img src={OBI} alt='Olimpíada Brasileira de Informática' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={OBLING} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://www.obm.org.br/' title='Olimpíada Brasileira de Matemática (OBM)' target='_blank' rel='noopener noreferrer'>
              <img src={OBM} alt='Olimpíada Brasileira de Matemática (OBM)' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='http://www.obmep.org.br/' title='Olimpíada Brasileira de Matemática das Escolas Públicas - OBMEP' target='_blank' rel='noopener noreferrer'>
              <img src={OBMEP} alt='Olimpíada Brasileira de Matemática das Escolas Públicas - OBMEP' />
            </a>
          </div>
        </div>
        <div css={sectionHeading} style={{ marginTop: 30 }}>
          <div style={{ display: 'flex' }}>
            <a href='https://brazilianbrainbee.org/' title='Olimpíada Brasileira de Neurociências (OBN)' target='_blank' rel='noopener noreferrer'>
              <img src={OBN} alt='Olimpíada Brasileira de Neurociências (OBN)' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='http://www.obr.org.br/' title='Olimpíada Brasileira de Robótica' target='_blank' rel='noopener noreferrer'>
              <img src={OBR} alt='Olimpíada Brasileira de Robótica' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://olimpiada.fiocruz.br/' title='Olimpíada Brasileira de Saúde e Meio Ambiente - Fiocruz' target='_blank' rel='noopener noreferrer'>
              <img src={OBSMA} alt='Olimpíada Brasileira de Saúde e Meio Ambiente - Fiocruz' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={ON} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://onciencias.org/' title='Olimpíada Nacional de Ciências' target='_blank' rel='noopener noreferrer'>
              <img src={ONC} alt='Olimpíada Nacional de Ciências' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://inova.rs.gov.br/4-olimpiada-nacional-de-aplicativos-recebe-inscricoes-e-tem-novidades-no-edital' title='Olimpíada Nacional de Aplicativos (Onda)' target='_blank' rel='noopener noreferrer'>
              <img src={ONDA} alt='Olimpíada Nacional de Aplicativos (Onda)' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={ONHB} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='https://obquimica.org/' title='Programa Nacional Olimpíadas de Química' target='_blank' rel='noopener noreferrer'>
              <img src={PNOQ} alt='Programa Nacional Olimpíadas de Química' />
            </a>
          </div>
        </div>
        <div css={sectionHeading} style={{ marginTop: 30 }}>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={POCKET} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={RPN} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={SAPIENTIA} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={VITALIS} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={white} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={white} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={white} alt='' />
            </a>
          </div>
          <div style={{ display: 'flex' }}>
            <a href='/' title='' target='_blank' rel='noopener noreferrer'>
              <img src={white} alt='' />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default FooterSite
