import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class SimpleSlider extends React.Component {

  render() {
    var settings = {
      className: "slider variable-width",
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000
          }
        }
      ]
    }
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <h2>255</h2>
            <h3>ATIVIDADES VIRTUAIS</h3>
          </div>
          <div>
            <h2>27</h2>
            <h3>Instituições<br />Participantes</h3>
          </div>
          <div>
            <h2>220</h2>
            <h3>HORAS DE<br /> PROGRAMAÇÃO</h3>
          </div>
          <div>
            <h2>280</h2>
            <h3>PALESTRANTES</h3>
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default SimpleSlider