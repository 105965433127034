import React, { Component, Fragment } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import Bg from '../images/bg-desktop.png'
import BgMobile from '../images/bg-mobile.png'

import {
  desktop,
  mobile
} from './styles/ComoEstudar.styles'

class DemoCarousel extends Component {
  render() {
    return (
      <Fragment>
        <Carousel css={desktop} dots={false} autoPlay showStatus={false} showThumbs={false} boolean={false} infiniteLoop={true} interval={5000}>
          <div>
            <img src={Bg} alt='/' />
          </div>
        </Carousel>
        <Carousel css={mobile} autoPlay showStatus={false} showArrows={false} showThumbs={false} boolean={false} infiniteLoop={true} interval={8000}>
          <div>
            <img src={BgMobile} alt='/' />
          </div>
        </Carousel>
      </Fragment>
    );
  }
}

export default DemoCarousel