import { css } from '@emotion/core'

export const inspired = css`
  position: relative;
  padding: 40px 0;
  background: #fff;
  font-family: 'Barlow Semi Condensed', sans-serif;
`
export const title = css`
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  color: #026FC2;
  text-transform: uppercase;
  margin-bottom: 40px;
  span {
    font-weight: bold;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
  }
`

export const contentView = css`
  position: relative;
  padding: 36px 24px 36px 24px;
  width: 322px;
  background: #FFFFFF;
  box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: default;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
  transition: all 0.25s linear 0s;
  text-align: center;
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #026FC2;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  h3 {
    font-size: 18px;
    line-height: 27px;
    color: #363732;
    height: 200px;
  }
  :hover {
    box-shadow: 0 5px 25px rgba(0,0,0,.1);
    transform: translate(0px, -4px);
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    margin: 0 18px
  }
  /* @media (min-width: 260px) and (max-width: 980px) {
    margin-bottom: 80px;
  } */
`

export const columns = css`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    width: 100%;
  }
`

export const column = css`
  flex: 1 0 18%; 
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const btn = css`
  width: 328px;
  height: 65px;
  border-radius: 4px;
  background: #026FC2;
  /* border: 1px solid #026FC2; */
  color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  display: flex;
  font-weight: bold;
  font-size: 18px; 
  line-height: 22px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  cursor: pointer;
  outline: none;
  a {
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 16px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`
export const visionBox = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: box-shadow 0.5s ease;
  p {
    font-size: 24px;
    line-height: 120%;
    color: #C4EFFA;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
`