import { css } from '@emotion/core'

export const footer = css`
  background: #fff;
  :focus {
    outline: 0;
  }
  h2 {
    font-size: 14px;
    color: #363732;
    text-transform: uppercase;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #026FC2;
    text-align: center;
    margin-bottom: 32px;
    font-weight: bold;
  }
`
export const sectionHeading = css`
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
  div:not(:last-child){
    margin-right: 24px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    div:not(:last-child){
      margin-right: 0px;
      margin-bottom: 24px;
    }
  }
`
export const SocialMidia = css`
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
  img {
    transition: 0.5s all ease;
    :hover {
      transition: 0.5s all ease;
      box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
    }
  }
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: row;
    margin-bottom: 42px;
  }
`
