import { css } from '@emotion/core'

export const StyleJornadaSapientia = css`
  position: relative;
  padding-top: 40px;
  padding-bottom: 45px;
  z-index: 1;
  background: #1072BA;
  font-family: 'Barlow Semi Condensed', sans-serif;
  text-align: left;
`
export const aboutContent = css`
  p {
    font-size: 18px;
    line-height: 200%;
    color: #fff;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    text-align: left !important;
  }
`
export const title = css`
  font-size: 48px;
  line-height: 100%;
  color: #FFF;
  margin-bottom: 32px;
  text-align: left;
  span {
    font-weight: bold;
  }
`
export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  &:not(:last-child):after {
    margin: 24px 0;
    content: '';
    border: 1px solid #EFEFEF;
    width: 100%;
    display: inline-block;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 5px 0;
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const desktop = css`
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none;
  }
`
export const mobile = css`
  img {
    width: 100%;
  }
  @media (min-width: 1001px) {
    display: none;
  }
`
