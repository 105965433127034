import React from 'react'
import ItemsNaMidia from './ItemsNaMidia'
import {
  StyleEdicaoSapientia,
  title,
  container,
  aboutContent,
  columns,
  column,
  counters
} from './styles/NaMidiaHome.styles'

const AOlimpiada = props => (
  <div css={StyleEdicaoSapientia}>
    <div css={container}>
      <div css={columns}>
        <div css={column}>
          <div css={aboutContent}>
            <div css={title}>MÊS NACIONAL DA CIÊNCIA,TECNOLOGIA e INOVAÇÕES</div>
          </div>
        </div>
      </div>
      <div css={counters}>
        <ItemsNaMidia />
      </div>
    </div>
  </div>
)

export default AOlimpiada
