import { css } from '@emotion/core'
// import BgVideo from '../../images/bg-como-estudar.png'

export const StyleJornadaSapientia = css`
  position: relative;
  padding-top: 40px;
  padding-bottom: 45px;
  z-index: 1;
  background: #FFF;
  font-family: 'Nunito Sans', sans-serif;
  text-align: left;
`
export const aboutContent = css`
  p {
    font-size: 18px;
    line-height: 200%;
    color: #565656;
    b {
      font-weight: bold;
    }
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
    color: #026FC2;
    font-weight: bold;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    text-align: left !important;
    p {
      margin-right: 14px;
      margin-left: 14px;
    }
    h3 {
      margin-right: 14px;
      margin-left: 14px;
    }
  }
`
export const title = css`
  font-weight: 300;
  font-size: 48px;
  line-height: 99%;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1D3A52;
  margin: 32px 0;
  @media (min-width: 260px) and (max-width: 736px) {
    margin-right: 14px;
    margin-left: 14px;
  }
`
export const title2 = css`
  font-weight: 300;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1D3A52;
  margin: 32px 0;
  @media (min-width: 260px) and (max-width: 736px) {
    margin-right: 14px;
    margin-left: 14px;
  }
`
export const subTitle = css`
  text-align: left;
  font-weight: 900;
  font-size: 14px;
  line-height: 99%;
  letter-spacing: 11px;
  text-transform: uppercase;
  color: #00A876;
  @media (min-width: 260px) and (max-width: 736px) {
    margin-right: 14px;
    margin-left: 14px;
  }
`
export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 5px 0;
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const desktop = css`
  margin-top: 100px;
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none;
  }
`
export const mobile = css`
  margin-top: 100px;
  @media (min-width: 1001px) {
    display: none;
  }
`

export const btn= css`
  font-family: 'Nunito Sans', sans-serif;
  width: 310px;
  background: #214258;
  border-radius: 9px;
  color: #FFFFFF;
  padding: 22px;
  margin-top: 32px;
  transition: 0.5s all ease;
  font-weight: 900;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
  }
`

export const linksHow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  h3 {
    font-size: 18px;
    line-height: 22px;
    color: #026FC2;
    font-weight: bold;
    margin-left: 24px;
    margin-right: 24px;
    width: 100px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    margin-right: 14px;
    margin-left: 14px;
  }
`
export const borderLine = css`
  border: 1px solid #D1DBE3;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const btnAbout = css`
  width: 310px;
  background: #026FC2;
  border-radius: 4px;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  padding: 22px;
  font-weight: bold;
  margin-top: 80px;
  margin: 0 auto;
`
export const apps = css`
  img {
    transition: 0.5s all ease;
    :hover {
      transition: 0.5s all ease;
      box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
    }
  }
  @media (min-width: 260px) and (max-width: 736px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    img:first-child {
      margin-right: 0px !important;
    }
  }
`

export const AppContent = css`
  margin-top: 100px;
  @media (min-width: 260px) and (max-width: 736px) {
    margin-top: 0px;
  }
`