import { css } from '@emotion/core'

export const StyleEdicaoSapientia = css`
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;  
  background: #FFF;
  font-family: 'Nunito Sans', sans-serif;
  text-align: left;
  background-color: #1D3A52;
  background-size: cover;
  background-position: center;
  border-bottom: 10px solid #F9E91F;
`
export const aboutContent = css`
  p {
    font-size: 18px;
    line-height: 200%;
    color: #444444;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
    color: #026FC2;
    font-weight: bold;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    text-align: left !important;
  }
`
export const title = css`
  font-weight: bold;
  font-size: 32px;
  line-height: 99%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #00A876;
  @media (min-width: 260px) and (max-width: 736px) {
    margin-left: 14px;
    margin-right: 14px;
    font-size: 24px;
  }
`
export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin: 5px 0;
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const desktop = css`
  margin-top: 100px;
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none;
  }
`
export const mobile = css`
  margin-top: 100px;
  @media (min-width: 1001px) {
    display: none;
  }
`
export const btnAbout = css`
  width: 328px;
  height: 65px;
  background: transparent;
  border-radius: 4px;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  margin-top: 80px;
  margin: 0 auto;
  color: #FFFFFF !important;
  border: 2px solid #FFFFFF;
  display: flex;
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`

export const counters = css`
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
  h2 {
    font-weight: bold;
    font-size: 88px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFEF2;
  }
  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 99%;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`